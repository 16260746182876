@import '../../shared/styles/bootstrap_breakpoints';
@import '../../shared/styles/colors';

.rectangle-listing {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	max-width: 100vw;
	padding: 0;
	@include media-breakpoint-up(md) {
		flex-direction: row;
		flex-wrap: wrap;
		padding: 0 2rem;
	}

	.rectangle-link {
		display: flex;
		flex: 1;
		font-size: 1.25rem;
		justify-content: center;
		margin: 0 2rem 1.25rem;
		padding-top: 1.75rem;
		padding-bottom: 1.75rem;
		text-align: center;

		@include media-breakpoint-up(md) {
			margin: 0 0.5rem 1rem 0;

			&.flipped {
				margin: 0 0 1rem 0.5rem;
			}
		}

		&.aqua {
			background-color: $theme-white;
			border: 4px solid $theme-aqua;
			color: $theme-aqua;
		}

		&.red {
			background-color: $theme-white;
			border: 4px solid $theme-red;
			color: $theme-red;
		}

		&.blue {
			background-color: $theme-white;
			border: 4px solid $theme-blue;
			color: $theme-blue;
		}

		&.green {
			background-color: $theme-white;
			border: 4px solid $theme-green;
			color: $theme-green;
		}

		&.orange {
			background-color: $theme-white;
			border: 4px solid $theme-orange;
			color: $theme-orange;
		}

		&.purple {
			background-color: $theme-white;
			border: 4px solid $theme-purple;
			color: $theme-purple;
		}

		@include media-breakpoint-up(md) {
			background-color: $background-off-white !important;
			border: none !important;
			flex: 1 0 30%;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			padding: 1.25rem 1.875rem;

			div {
				padding-left: 1.25rem;
				flex: 1;
			}
		}
	}
}

.library-home-topic-title{
	color: #606060;
	font-size: 13px;
	font-weight: bold;
	margin-top: 10px;
}

.preserve-on-mobile{
	background-color: $background-off-white !important;
	border: none !important;
	flex: 1 0 30%;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 1.25rem 1.875rem;

	div {
		padding-left: 1.25rem;
		flex: 1;
	}
}

