$theme-aqua: #129595;
$theme-aqua-dark: darken($theme-aqua, 10%);
$theme-footer-white: #f9f9f9;
$theme-grey: #606060;
$theme-header-grey: #dddddd;
$theme-white: #ffffff;

$theme-blue: #22509f;
$theme-blue-dark: #062b71;
$theme-green: #22745a;
$theme-green-dark: #0f4332;
$theme-orange: #dd4018;
$theme-orange-dark: #791e14;
$theme-purple: #643289;
$theme-purple-dark: #371c5c;
$theme-red: #ca2542;
$theme-red-dark: #610612;

$background-white: $theme-white;
$background-off-white: $theme-footer-white;
$background-footer-white: $theme-footer-white;

$text-default: $theme-grey;
$text-aqua: $theme-aqua;
