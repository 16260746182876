@import "../../shared/styles/colors";
@import "../../shared/styles/bootstrap_breakpoints";

.library-home-blue {
  color: $theme-blue;
}

.library-home-red {
  color: $theme-red;
}

.library-home-green {
  color: $theme-green;
}

.library-home-purple {
  color: $theme-purple;
}

.library-home-orange {
  color: $theme-orange;
}

.library-home-cat-header {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  padding: 0 2rem;
  flex-direction: row;
  display: flex;
  align-items: center;
}

.library-home-cat-header-text {
  width: 250px;
  margin-right: 30px;
}

.library-home-topic-wrapper {
  display: flex;
  margin-bottom: 25px;
  padding: 0 2rem;
  overflow-y: hidden;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.library-home-topic-wrapper::-webkit-scrollbar {
  display: none;
}

.library-home-topic-container {
  width: 120px;
  margin-right: 10px;
}

.library-home-search-listing {
  width: 100%;
}

.library-home-search-box-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 45px;
  box-shadow: 0 5px 5px 0 #ccc;
  padding: 0 2rem;
  background-color: $theme-footer-white;
}

.library-home-general-information {
  margin-bottom: 30px;
}

.library-home-no-results {
  width: 100%;
  height: 100%;
  align-content: center;
  justify-content: center;
  display: flex;
  font-size: 20px;
}

.library-home-search-clear {
  position: absolute;
  right: 15px;

  margin: auto;
  font-size: 45px;
  cursor: pointer;
  color: $theme-aqua;
}

.library-home-search-box-wrapper {
  position: relative;
  margin: 20px 0;
  width: 100%;
}

.library-home-search-box-wrapper input {
  border: 4px solid $theme-aqua;
  font-size: 20px;
  width: 100%;
  height: 60px;
  padding-left: 70px;
  box-sizing: border-box;

  @include media-breakpoint-up(sm) {
    min-width: 286px;
  }
}

#searchIcon {
  position: absolute;
  top: 16px;
  width: 30px;
  height: 30px;
  margin-left: 30px;
}

@include media-breakpoint-up(md) {
  .library-home-search-box-container {
    background-color: transparent;
    box-shadow: none;
    margin-bottom: 30px;
  }

  .library-home-search-box-wrapper {
    margin-top: 40px;
  }
}
