@import '../../styles/bootstrap_breakpoints';
@import '../../styles/colors';

.header {
	background-color: $theme-aqua;
	width: 100%;
	z-index: 10;

	@include media-breakpoint-up(md) {
		&.fixed {
			height: 103px;
			position: fixed;
			top: 0;
			left: 0;
		}
	}

	header {
		align-items: center;
		box-sizing: border-box;
		color: $theme-white;
		display: none;
		flex-direction: row;
		justify-content: space-around;
		margin: 0 auto;
		max-width: 888px;
		padding: 1.875rem 2rem;
		z-index: 15;

		@include media-breakpoint-up(md) {
			display: flex;
			&.fixed {
				left: 0;
				max-width: 888px;
				position: fixed;
				right: 0;
				top: 0;
				width: 100%;
			}
		}

		svg {
			height: 2.5rem;

			path {
				fill: $theme-white !important;
			}

			&.nav-toggle {
				cursor: pointer;
				flex: 0 1 auto;
			}
		}

		.helix {
			display: flex;
			flex: 1;
			justify-content: center;

			a {
				padding-right: 2.5rem;
			}
		}
	}
}
