@import '../../shared/styles/bootstrap_breakpoints';
@import '../../shared/styles/colors';

.banner {
	height: 6.25rem;
	position: relative;
	margin-bottom: 1.875rem;
	padding: 0;
	top: 0;

	@include media-breakpoint-up(md) {
		padding: 0 2rem;

		&.no-padding {
			padding: 0 !important;
		}

		&.no-margin {
			margin: 0 !important;
		}
	}

	&.hidden-desktop {
		@include media-breakpoint-up(md) {
			height: auto;
			margin: 0;

			.overlapping-container {
				color: $theme-grey;
				height: auto;
				position: relative;
			}
		}
	}

	.image {
		height: 100%;
		position: relative;
		overflow: hidden;
		width: auto;

		.mobile {
			@include media-breakpoint-up(md) {
				display: none;
			}
		}

		.desktop {
			display: none;

			@include media-breakpoint-up(md) {
				display: block;
			}
		}

		svg {
			display: block;
			height: auto;
			margin-left: 50%;
			min-width: 888px;
			transform: translateX(-50%);

			&.flipped {
				transform: translateX(-50%) scaleY(-1);
			}

			.background {
				fill: $theme-header-grey;
			}

			.wave {
				fill: $theme-aqua;
			}
		}
	}

	&.aqua {
		.image svg {
			.background {
				fill: $theme-header-grey;
			}

			.wave {
				fill: $theme-aqua;
			}
		}
		.overlapping-container svg {
			.circle {
				fill: $theme-white;
				stroke: $theme-aqua;
			}
			.x {
				stroke: $theme-aqua;
			}
		}
	}

	&.blue {
		.image svg {
			.background {
				fill: $theme-header-grey;
			}

			.wave {
				fill: $theme-blue;
			}
		}
		.overlapping-container svg {
			.circle {
				fill: $theme-white;
				stroke: $theme-blue;
			}
			.x {
				stroke: $theme-blue;
			}
		}
	}

	&.red {
		.image svg {
			.background {
				fill: $theme-header-grey;
			}

			.wave {
				fill: $theme-red;
			}
		}
		.overlapping-container svg {
			.circle {
				fill: $theme-white;
				stroke: $theme-red;
			}
			.x {
				stroke: $theme-red;
			}
		}
	}

	&.green {
		.image svg {
			.background {
				fill: $theme-header-grey;
			}

			.wave {
				fill: $theme-green;
			}
		}
		.overlapping-container svg {
			.circle {
				fill: $theme-white;
				stroke: $theme-green;
			}
			.x {
				stroke: $theme-green;
			}
		}
	}

	&.orange {
		.image svg {
			.background {
				fill: $theme-header-grey;
			}

			.wave {
				fill: $theme-orange;
			}
		}
		.overlapping-container svg {
			.circle {
				fill: $theme-white;
				stroke: $theme-orange;
			}
			.x {
				stroke: $theme-orange;
			}
		}
	}

	&.purple {
		.image svg {
			.background {
				fill: $theme-header-grey;
			}

			.wave {
				fill: $theme-purple;
			}
		}
		.overlapping-container svg {
			.circle {
				fill: $theme-white;
				stroke: $theme-purple;
			}
			.x {
				stroke: $theme-purple;
			}
		}
	}

	&.aqua.colorfill {
		.image svg {
			.wave {
				fill: $theme-aqua-dark;
			}

			.background {
				fill: $theme-aqua;
			}
		}
		.overlapping-container svg {
			.circle {
				fill: #00000000;
				stroke: $theme-white;
			}
			.x {
				stroke: $theme-white;
			}
		}
	}

	&.blue.colorfill {
		.image svg {
			.wave {
				fill: $theme-blue-dark;
			}
			.background {
				fill: $theme-blue;
			}
		}
		.overlapping-container svg {
			.circle {
				fill: #00000000;
				stroke: $theme-white;
			}
			.x {
				stroke: $theme-white;
			}
		}
	}

	&.red.colorfill {
		.image svg {
			.wave {
				fill: $theme-red-dark;
			}
			.background {
				fill: $theme-red;
			}
		}
		.overlapping-container svg {
			.circle {
				fill: #00000000;
				stroke: $theme-white;
			}
			.x {
				stroke: $theme-white;
			}
		}
	}

	&.green.colorfill {
		.image svg {
			.wave {
				fill: $theme-green-dark;
			}
			.background {
				fill: $theme-green;
			}
		}
		.overlapping-container svg {
			.circle {
				fill: #00000000;
				stroke: $theme-white;
			}
			.x {
				stroke: $theme-white;
			}
		}
	}

	&.orange.colorfill {
		.image svg {
			.wave {
				fill: $theme-orange-dark;
			}
			.background {
				fill: $theme-orange;
			}
		}
		.overlapping-container svg {
			.circle {
				fill: #00000000;
				stroke: $theme-white;
			}
			.x {
				stroke: $theme-white;
			}
		}
	}

	&.purple.colorfill {
		.image svg {
			.wave {
				fill: $theme-purple-dark;
			}
			.background {
				fill: $theme-purple;
			}
		}
		.overlapping-container svg {
			.circle {
				fill: #00000000;
				stroke: $theme-white;
			}
			.x {
				stroke: $theme-white;
			}
		}
	}

	.overlapping-container {
		color: white;
		height: 6.25rem;
		left: 0;
		position: relative;
		top: -100%;
		width: 100%;

		.banner-exit,
		.banner-close,
		.banner-back,
		.hide-desktop,
		.hide-mobile {
			display: flex;
			flex-direction: column;
			height: 100%;
			justify-content: center;

			h5 {
				cursor: pointer;
				margin: 0;
				margin-bottom: 1.875rem;
				padding-left: 2rem;

				@include media-breakpoint-up(md) {
					padding-left: 0;
				}				
			}

			svg {
				box-sizing: content-box;
				cursor: pointer;
				padding-left: 2rem;
			}
		}
	}
}
