@import '../../shared/styles/bootstrap_breakpoints';
@import '../../shared/styles/colors';

svg {
	&.flipped {
		transform: scaleX(-1);
	}

	&.wave-icon {
		display: block;
		flex: 0 1 7.5rem;
		width: 7.5rem;

		&.md-up-only {
			display: none;

			@include media-breakpoint-up(md) {
				display: block;
				flex: 0 1 7.5rem;
				width: 7.5rem;
			}
		}

		&.aqua {
			rect {
				fill: $theme-header-grey;
			}

			path {
				fill: $theme-aqua;
			}
		}

		&.blue {
			color: $theme-blue;

			rect {
				fill: $theme-blue;
			}

			path {
				fill: $theme-blue-dark;
			}
		}
		&.red {
			color: $theme-red;

			rect {
				fill: $theme-red;
			}

			path {
				fill: $theme-red-dark;
			}
		}
		&.orange {
			color: $theme-orange;

			rect {
				fill: $theme-orange;
			}

			path {
				fill: $theme-orange-dark;
			}
		}
		&.purple {
			color: $theme-purple;

			rect {
				fill: $theme-purple;
			}

			path {
				fill: $theme-purple-dark;
			}
		}
		&.green {
			color: $theme-green;

			rect {
				fill: $theme-green;
			}

			path {
				fill: $theme-green-dark;
			}
		}
	}
}
