.navigation-library-bottom-wrapper {
	width: 100%;
	height: 100px;
	background-color: white;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	justify-items: center;
	align-items: center;

}

.wrapper-back-green {	
	filter: invert(35%) sepia(65%) saturate(453%) hue-rotate(110deg) brightness(90%) contrast(86%);
}

.wrapper-back-blue {
	filter: invert(20%) sepia(60%) saturate(2415%) hue-rotate(206deg) brightness(98%) contrast(85%);
}

.wrapper-back-red {
	filter: invert(20%) sepia(79%) saturate(2879%) hue-rotate(334deg) brightness(86%) contrast(91%);
}

.wrapper-back-purple {
	filter: invert(23%) sepia(65%) saturate(925%) hue-rotate(239deg) brightness(95%) contrast(98%);
}

.wrapper-back-orange {
	filter: invert(30%) sepia(84%) saturate(1799%) hue-rotate(351deg) brightness(88%) contrast(96%);
}