@import '../../styles/bootstrap_breakpoints';
@import '../../styles/colors';

nav {
	align-items: center;
	background-color: $theme-footer-white;
	box-sizing: border-box;
	display: flex;
	height: 6rem;
	margin: 0;
	position: fixed;
	bottom: 0;
	width: 100%;

	ul {
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		list-style: none;
		margin: 20px auto;
		padding: 0 2rem;
		width: 100%;

		@include media-breakpoint-up(sm) {
			padding-right: 2;
			padding-left: 2;
		}

		@include media-breakpoint-down(xs){
			padding: 0;
		}

		.nav-item {
			font-size: 0.8125rem;
			a {
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				color: $theme-grey;

			div {
				margin-top: 10px;
			}
				svg {
					display: block;
					max-height: 1.5625rem;

					path {
						fill: $theme-grey;
					}
				}
				div {
					// border: 2px solid red;
					text-align: center;

					span {
					color: $theme-grey;
					display: block;
					line-height: 1rem;
					}
				}
			}

			&.active {
				a {
					color: $theme-aqua;

					span {
						color: $theme-aqua;
					}

					svg {
						circle#Ellipse_3 {
							fill: $theme-aqua;
						}
						path {
							fill: $theme-aqua;
						}
					}
				}
			}
		}

		a:hover,
		a:focus {
			color: $theme-aqua;
			text-decoration: none;

			svg {
				circle#Ellipse_3 {
						fill: $theme-aqua;	
				}

				path {
					fill: $theme-aqua;
				}
			}

			div span {
					color: $theme-aqua;	
			}
		}
	}

	@include media-breakpoint-up(md) {
		align-items: flex-start;
		border-bottom: 1px black solid;
		display: none;
		height: 100vh;
		padding-top: 3.125rem;
		position: fixed;
		top: 103px;
		z-index: 5;

		&.open {
			display: flex;
		}

		ul {
			box-sizing: border-box;
			flex-direction: column;
			justify-content: flex-start;
			max-width: 888px;

			.nav-item {
				font-size: 2.25rem;
				line-height: 2.75rem;
				padding-bottom: 1.25rem;
				display: flex;

				&.active {
					a {
						color: $theme-aqua;
					}
				}

				a {
					svg {
						display: none;
					}
					div {
						span {
						display: inline;
						}							
					}

				}

			}
		}
	}
}
