@import "../../styles/bootstrap_breakpoints";
@import "../../styles/colors";

footer {
	display: none;
	width: 100%;
	background-color: $theme-footer-white;

	@include media-breakpoint-up(md) {
		display: flex;
		flex-direction: row;
		bottom: 0;
	}

	svg {
		height: 1.875rem;
		padding: 1.5rem 0;
		width: 5.375rem;
		margin: auto;
	}

	p {
		font-size: .75rem;
		padding: 1.5rem 0 0 1.5rem;
	}

	#leftFooter, #rightFooter {
		flex: 1
	}

}
