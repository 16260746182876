@import "../../shared/styles/bootstrap_breakpoints";

.button-active {
	background-color: #129595;
	color: #FFFFFF;
	margin-left: -37px;
	margin-right: -37px;
	// cursor: pointer;
}

.button-inactive {
	color: #129595;
	cursor: pointer;
}

.button-input {
	background-color: #129595;
	border: 4px solid #FFFFFF;
	width: 286px;
	height: 60px;
	margin-bottom: 20px;
	text-align: left;
	padding-left: 4px;
	color: #FFFFFF;
	
}

.button-input::placeholder {
		color: white;
		font-weight: 300;
		font-size: 20px;
}

.button-container {
	height: 70px;
	margin: 0 auto 10px;
	background-color: #F9F9F9;
	margin-right: -37px;
	margin-left: -37px;
	display: flex;
  align-items: center;
	justify-content: center;
	position: relative;
	
}



.button-container-active {
	background-color: #129595;
	margin-left: 0;
	margin-right: 0;
	margin-bottom: 10px;
}


.button-button {
	border:none;
	outline:none;
	background-color: transparent;
}

.button-text {
	font-size: 20px;
	// cursor: pointer;
}


.button-close-container{
	position: relative;
	z-index: 2;
	cursor: pointer;
}

.button-close {
	position:absolute;
	top: 8px;
	right: 10%;
	width: 16px;
	height: 16px;
	color: white;
	font-size: 38px;
}

.button-close-span {
	color: white;
}

@include media-breakpoint-up(md) {

	.button-wrapper {
		// border: 2px solid green;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 10px 16px;

	}	

	.button-container {
		margin-left: 0px;
		margin-right: 0px;
		width: 409px;
	}

	.button-active {
		margin-left: 0px;
		margin-right: 0px;
	}			
}



