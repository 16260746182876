@import './shared/styles/normalize/normalize';
@import './shared/styles/bootstrap_breakpoints';
@import './shared/styles/colors';

body {
	color: $text-default;
	font-family: 'Helvetica Neue', sans-serif;
}

#root {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

h1 {
	font-weight: 400;
	font-size: 1.75rem;
	line-height: 2.125rem;
}

p,
ul,
ul li {
	font-size: 1.25rem;
	font-weight: 300;
	line-height: 1.5rem;
}

span {
	color: $theme-aqua;
	font-weight: 500;
}

a {
	color: $theme-aqua;
	font-weight: 500;
	text-decoration: none;

	// &:hover {
	// 	color: $theme-aqua-dark;
	// 	text-decoration: underline;
	// }
}

main {
	box-shadow: none;
	box-sizing: border-box;
	margin-right: auto;
	margin-left: auto;
	flex: 1;
	// padding-top: 1.875rem;
	padding-bottom: 5rem;
	max-width: 888px;
	width: 100%;

	section {
		background-color: #f9f9f9;
		box-sizing: border-box;
		margin-bottom: 0.625rem;
		padding: 1.25rem 2.375rem 1.25rem;
		width: 100%;

		p {
			padding: 0 0 1.25rem;

			&.centered {
				text-align: center;
			}
		}

		ul {
			list-style-position: outside;
			margin: 0;
			padding-top: 0;
			padding-bottom: 1.25rem;
			padding-left: 1.125rem;

			li {
				padding-top: 0.3125rem;
				padding-bottom: 0.3125rem;

				&:first-child {
					padding-top: 1.25rem;
				}

				&:last-child {
					padding-bottom: 1.25rem;
				}
			}

			&:last-child {
				padding-bottom: 0;

				li {
					&:last-child {
						padding-bottom: 0;
					}
				}
			}
		}

		&:last-child {
			margin-bottom: 0;
		}

		& p:last-child {
			padding-bottom: 0;
		}
	}

	p {
		&.centered {
			text-align: center;
		}
	}

	h1,
	p {
		margin: 0;
		padding: 0 2rem 1.25rem;
		&.pre-buttons {
			padding: 0 2rem 3.125rem;
		}
	}

	& > p:last-child {
		padding-bottom: 0;
	}
}

.container, .adjust-container {
	padding-left: 2rem;
	padding-right: 2rem;
}

.adjust-container-margin {
	margin-left: 2rem;
	margin-right: 2rem;
}

.small-full {
	padding-left: 0;
	padding-right: 0;
	
	@include media-breakpoint-up(md) {
		padding-left: 2rem;
		padding-right: 2rem;
	}
}

.small-full-margin {
	margin-left: 0;
	margin-right: 0;
	
	@include media-breakpoint-up(md) {
		margin-left: 2rem;
		margin-right: 2rem;
	}
}

.container-red {
	span, a {
		color: $theme-red;

		// &:hover {
		// 	color: $theme-red-dark;
		// }
	}
}

.container-blue {
	span, a {
		color: $theme-blue;

		
	}
}

.container-green {
	span, a {
		color: $theme-green;

		// &:hover {
		// 	color: $theme-green-dark;
		// }
	}
}

.container-purple {
	span, a {
		color: $theme-purple;

		// &:hover {
		// 	color: $theme-purple-dark;
		// }
	}
}

.container-orange {
	span, a {
		color: $theme-orange;

		// &:hover {
		// 	color: $theme-orange-dark;
		// }
	}
}

b {
	font-weight: 500;
}

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-up(md) {
	main {
		padding-top: 1.875rem;
		padding-bottom: 1.875rem;
	}
	.shadowbox {
		box-shadow: rgba(0, 0, 0, 0.29) 0 0.1875rem 0.375rem;
	}
	.hide-desktop {
		display: none !important;
		margin: 0 !important;
		padding: 0 !important;
	}
}

@include media-breakpoint-up(lg) {
}

@include media-breakpoint-up(xl) {
}

@include media-breakpoint-down(sm) {
	.hide-mobile {
		display: none !important;
	}
}
