@import "../../shared/styles/bootstrap_breakpoints";

.container {
	margin: 0 0 3.75rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
}


.container .row {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.container .row h1 {
	padding: 0;
	margin-bottom: 20px
}

.container .row p {
	padding: 0;
	margin-bottom: 30px;
}

.container .row h5 {
	display: none;
}

.row h3 {
	width: 286px;
	font-size: 28px;
	font-weight: 500;
}

.row .button-wrapper {
	display: flex;
	flex-direction: row;
	margin: 20px 0;
	width: 100%;
}

.submit-wrapper-button {
	// border: 2px solid red;
	// position: relative;
	grid-column-start: 1;
	grid-column-end: 3;
	display: flex;
	justify-content: center;
}

d-block {
	display: block;
}

d-none {
	display: none;
}

@include media-breakpoint-up(md) {
	.container {
		// border: 2px solid red;
	}

	.container .row h5 {
		display: block;
		color: #129595;
		cursor: pointer;
	}

	.row h3 {
		width: 100%;
		font-size: 28px;
		font-weight: 500;

	}
}

.rq-input-container {
	display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

}

.rq-input-container p {
	position: relative;
	right: 93px;
	top: 18px;
}


.active-button-wrapper {
	background-color: #129595;
}


