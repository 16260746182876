.audio-text-wrapper {
	height: auto;
	display: flex;
	flex-direction: column;
	padding: 0 2rem;
	justify-content: flex-start;
}

.audio-text {
	display: flex;
	justify-content: flex-start;
	padding: 0;
}

.audio-text h1 {
	padding: 0;
}



.audio-text-wrapper audio {
	align-self: center;
	margin: 30px 0;
	width: 286px;
}

@media all and (min-width: 768px) {
	.audio-text-wrapper {
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	.audio-text {
		justify-self: flex-start;
		margin: 0;
		margin-right: 40px;
	}

}