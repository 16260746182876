@import "../../shared/styles/bootstrap_breakpoints";


.helix-button div {
		color: #129595;
		font-weight: 600;
}

.helix-button {
	background-repeat: no-repeat;
	background-color: white;
	background-size: 100% 100%;
	border: none;
	cursor: pointer;
	height: 60px;
	min-height: 60px;
	margin: 20px 0;
	max-width: 100%;
	width: 286px;
}

 


